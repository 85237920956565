.edit-overlay {

    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    top: 0;
    left: 0;
    overflow-y: auto;
    scroll-behavior: smooth;
    height: 100%;
    width: 100%;
    z-index: 2;
    transform: scale3d(1, 1, 1) matrix(1, 0, 0, 1, 0, 0);
    transition: transform 1s cubic-bezier(0.19, 1, .22, 1), opacity .4s cubic-bezier(0.19, 1, .22, 1), left 0s ease 0s;
}

.edit-overlay.is-hidden {

    pointer-events: none;
    left: -9999px;
    opacity: 0;
    transform: scale3d(1.2, 1.2, 1.2) matrix(1.2, 0, 0, 1.2, 0, 0);
    transition: transform 1s cubic-bezier(0.19, 1, .22, 1), opacity .4s cubic-bezier(0.19, 1, .22, 1), left 0s ease 1s;
}

.edit-overlay__wrapper {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 5vmin;
}

.edit-overlay__input-wrapper {

    margin-bottom: 3vmin;
    width: calc(100vw - 40vmin);
    max-width: 500px;
}

.edit-overlay__wrapper .edit-overlay__input-wrapper:last-of-type {

    margin-bottom: 0;
}

.edit-overlay__input-wrapper label {

    margin-bottom: 1vmin;
}

.edit-overlay__input-wrapper input {

    font-size: 14px;
    padding: 1vmin;
    text-align: center;
    width: 100%;
}

.edit-overlay input,
.edit-overlay label {

    display: block;
}

.DateDefault-result {

    display: flex;
    flex-direction: column;
}

.swiper {

    --swiper-navigation-color: #fff;

    margin-top: 25vmin;
    max-width: 640px;
    width: calc(100vw - 10vmin);
}

.swiper-slide {

    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px !important;
}

.swiper h4 {

    padding: 0 4vmin;
}

.swiper-button-next,
.swiper-button-prev {

    --swiper-navigation-size: 22px;
}

.edit-overlay__image-wrapper {

    position: relative;
}