.App {

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  overflow: hidden;
  min-height: 100vh;
}

.App-header {

  position: fixed;
  display: flex;
  justify-content: space-between;
  top: 4vmin;
  left: 4vmin;
  width: calc(100% - 8vmin);
}

img {

  object-fit: cover;
  height: auto;
  max-width: 100%;
}
input[type="file"] {
  color: #fff;
}