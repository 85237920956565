.Button {

    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    border: 0;
    margin: 0;
    color: #fff;
    font-weight: bold;
    font-size: 22px;
    padding: 22px;
    -webkit-appearance: button;
}

.Button--ghost {

    border: 2px solid #fff;
    padding: 12px 30px;
    transition: color 233ms ease-in-out, border-color 233ms ease-in-out, background-color 233ms ease-in-out;
}

.Button--ghost:hover,
.Button--ghost:focus {

    border: 2px solid #000;
    background-color: #fff;
    color: #000;
}

.Button--close {

    position: fixed;
    top: 4vmin;
    right: 4vmin;
}

.Button img {

    pointer-events: none;
    height: 24px;
    width: auto;
}

.Button--delete {
    position: absolute;
    font-size: 10px;
    padding: 8px;
    top: 10px;
    right: 10px;
}

.Button.is-hidden {
    display: none;
}